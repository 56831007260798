import React , { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { isLoggedIn } from '../Utils/index';
import PublicRoutes from './PublicRoutes';  

function Auth() {
    return isLoggedIn() ? (
        <Redirect to = '/dashboard/overview'/>
    ) : (
        <PublicRoutes/>
    )
};

export default memo(Auth);