// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-container {
  padding: 20px 25px 25px 205px;
}

.leaderboard_section {
  display: flex;
  margin: 25px 0;
  justify-content: space-between;
  border-radius: 4px;
}
.leaderboard_section .barchart_wrapper {
  width: 67%;
}
.leaderboard_section .leaderboard_wrapper {
  width: 32%;
  text-align: center;
}
.leaderboard_section .tabs_wrapper .focus {
  border-bottom: 3px solid #6aa3f9;
}
.leaderboard_section .granuality {
  padding: 10px;
  border-bottom: 3px solid #c4c4c4;
  cursor: pointer;
  width: 25%;
}

@media (max-width: 620px) {
  .overview-container {
    padding: 0px;
  }
  .leaderboard_section .barchart_wrapper {
    width: 32%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Overview/style.scss"],"names":[],"mappings":"AACA;EACI,6BAAA;AAAJ;;AAEA;EACI,aAAA;EACA,cAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AAAI;EACI,UAAA;AAER;AAAI;EACI,UAAA;EACA,kBAAA;AAER;AAAI;EACI,gCAAA;AAER;AAAI;EACI,aAAA;EACA,gCAAA;EACA,eAAA;EACA,UAAA;AAER;;AAEA;EACI;IACI,YAAA;EACN;EAEM;IACI,UAAA;EAAV;AACF","sourcesContent":["@import '../../../constant.scss';\n.overview-container{\n    padding: 20px 25px 25px 205px;\n}\n.leaderboard_section{\n    display: flex;\n    margin: 25px 0;\n    justify-content: space-between;\n    border-radius: 4px;\n    .barchart_wrapper{\n        width: 67%;\n    }\n    .leaderboard_wrapper{\n        width: 32%;\n        text-align: center;\n    }\n    .tabs_wrapper .focus{\n        border-bottom: 3px solid #6aa3f9;\n    }\n    .granuality{\n        padding: 10px;\n        border-bottom: 3px solid #c4c4c4;\n        cursor: pointer;\n        width: 25%;\n    }\n}\n\n@media (max-width: 620px) {\n    .overview-container{\n        padding: 0px;\n    }\n    .leaderboard_section{\n        .barchart_wrapper{\n            width: 32%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
