// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_wrapper {
  width: 27%;
  background-color: #ffffff;
  text-align: center;
  border-radius: 8px;
  padding: 25px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.card_wrapper .card_name {
  color: #555b6c;
  font-size: 1.4em;
  margin-bottom: 12px;
}
.card_wrapper .card_count {
  color: #000000;
  font-size: 2.5em;
}
.card_wrapper:hover {
  transform: scale(1.01);
}

@media (max-width: 1120px) {
  .card_wrapper {
    width: 25%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Common/cards/style.scss","webpack://./src/constant.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,2CAAA;AADJ;AAEI;EACI,cCXc;EDYd,gBAAA;EACA,mBAAA;AAAR;AAEI;EACI,cCfgB;EDgBhB,gBAAA;AAAR;AAEI;EACI,sBAAA;AAAR;;AAIA;EACI;IACI,UAAA;EADN;AACF","sourcesContent":["@import '../../../constant.scss';\n\n.card_wrapper{\n    width: 27%;\n    background-color: #ffffff;\n    text-align: center;\n    border-radius: 8px;\n    padding: 25px;\n    cursor: pointer;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n    .card_name{\n        color: $primary-text-color;\n        font-size: 1.4em;\n        margin-bottom: 12px;\n    }\n    .card_count{\n        color :$secondary-text-color;\n        font-size: 2.5em;\n    }\n    &:hover{\n        transform: scale(1.01);\n    }\n}\n\n@media (max-width: 1120px){\n    .card_wrapper{\n        width: 25%;\n    }\n}","$primary-text-color : #555b6c;\n$secondary-text-color : #000000;\n$primary-blue-color : #151e33;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
