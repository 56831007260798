import React, { useState, useEffect } from "react";
import "./style.scss";
import Cards from "../../Common/cards/index";
import TableComponent from "../../Common/TableComponent";
import axios from "axios";
import { globalConfig } from "../../../config/pathconfig/globalConfig";
import { userTableHeader } from "../../Common/TableComponent/constants";
import TextField from "@mui/material/TextField";
import { FiSearch } from "react-icons/fi";
import { getHeaders } from "../../../config/Utils";

const Users = () => {
  const [cardData, setCardData] = useState([]);
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isAscending, setIsAscending] = useState(false);

  useEffect(() => {
    loadHandler();
  }, []);

  const loadHandler = () => {
    axios
      .get(`${globalConfig.api_path}/users-view`, {
        headers: getHeaders(),
      })
      .then((response) => {
        setCardData(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${globalConfig.api_path}/users-list`, {
        headers: getHeaders(),
      })
      .then((response) => {
        const newResponse = response.data.items.map((item) => ({
          userData: item.userData,
          avg_time_spent: item.avg_time_spent,
          completed_courses: item.completed_courses,
          enrolled_courses: item.enrolled_courses,
        }));
        setData(newResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sortHandler = (item) => {
    const updatedTableData = [...data];

    if (item === "userData") {
      updatedTableData.sort((a, b) => {
        const compareResult = isAscending
          ? b[item].name.localeCompare(a[item].name, undefined, {
              numeric: true,
              sensitivity: "base",
            })
          : a[item].name.localeCompare(b[item].name, undefined, {
              numeric: true,
              sensitivity: "base",
            });

        return compareResult;
      });
    } else {
      updatedTableData.sort((a, b) => {
        const compareResult = isAscending
          ? b[item].toString().localeCompare(a[item].toString(), undefined, {
              numeric: true,
              sensitivity: "base",
            })
          : a[item].toString().localeCompare(b[item].toString(), undefined, {
              numeric: true,
              sensitivity: "base",
            });

        return compareResult;
      });
    }

    setData(updatedTableData);
    setIsAscending(!isAscending);
  };

  const search = (event) => {
    const updatedData = data.filter((item) =>
      item.userData.name
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );

    setSearchedData(updatedData);
    setInputValue(event.target.value);
  };

  return (
    <div className="user_wrapper">
      <div className="d-flex justify-space-around cards_flex">
        {!!cardData &&
          cardData.map((item, index) => (
            <Cards
              key={index}
              header={item.data.display_name}
              headerCount={item.data.value}
            />
          ))}
      </div>
      <div className="container-main">
        <div className="user-search">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => search(e)}
          />
          <span className="search-icon">
            <FiSearch />
          </span>
        </div>
        <div className="table-component-container">
          {inputValue !== "" ? (
            <TableComponent
              tableData={searchedData}
              tableHeader={userTableHeader}
              sortHandler={sortHandler}
            />
          ) : (
            <TableComponent
              tableData={data}
              tableHeader={userTableHeader}
              sortHandler={sortHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
