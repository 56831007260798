// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  width: 100%;
  min-width: 800px;
}

table td,
table th {
  padding: 12px;
  right: none;
  text-align: center;
  border-bottom: 1px solid rgba(21, 30, 51, 0.2);
}

table td:last-child {
  font-weight: 600;
}

table tr:hover {
  background-color: #ddd;
}

table thead th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: none;
  color: #41434d;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: 1px solid #cfa973;
}

.data_table_wrapper {
  overflow: auto;
}

.table_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_profile h4 {
  text-align: center;
  font-weight: bold;
}
.table_profile h5 {
  color: #9e9fa6;
  margin-top: 4px;
}
.table_profile .align_left {
  text-align: left;
}

.table-wrapper th {
  cursor: pointer;
}
.table-wrapper th svg {
  font-size: 16px;
  margin-bottom: -3px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/TableComponent/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,gBAAA;AACF;;AAEA;;EAEE,aAAA;EACA,WAAA;EACA,kBAAA;EACA,8CAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,iBAAA;EACA,oBAAA;EACA,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,0BAAA;EACA,gCAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,cAAA;EACA,eAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;;AAGE;EACE,eAAA;AAAJ;AACI;EACE,eAAA;EACA,mBAAA;AACN","sourcesContent":["table {\n  border-collapse: collapse;\n  width: 100%;\n  min-width: 800px;\n}\n\ntable td,\ntable th {\n  padding: 12px;\n  right: none;\n  text-align: center;\n  border-bottom: 1px solid rgba(21, 30, 51, 0.2);\n}\n\ntable td:last-child {\n  font-weight: 600;\n}\n\ntable tr:hover {\n  background-color: #ddd;\n}\n\ntable thead th {\n  padding-top: 12px;\n  padding-bottom: 12px;\n  background-color: none;\n  color: #41434d;\n  font-weight: 600;\n  text-transform: capitalize;\n  border-bottom: 1px solid #cfa973;\n}\n\n.data_table_wrapper {\n  overflow: auto;\n}\n\n.table_profile {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  h4 {\n    text-align: center;\n    font-weight: bold;\n  }\n  h5 {\n    color: #9e9fa6;\n    margin-top: 4px;\n  }\n  .align_left {\n    text-align: left;\n  }\n}\n\n.table-wrapper {\n  th {\n    cursor: pointer;\n    svg {\n      font-size: 16px;\n      margin-bottom: -3px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
