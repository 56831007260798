import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  IoArrowBackCircleOutline,
  IoChevronDown,
  IoChevronUp,
  IoSearch,
  IoClose,
} from "react-icons/io5";
import {
  MdViewModule,
  MdOndemandVideo,
  MdPlaylistAddCheck,
} from "react-icons/md";
import { FaUsers, FaSearch } from "react-icons/fa";
import "./styles.scss";

function YourComponent() {
  const { id } = useParams();
  const history = useHistory();
  const [modules, setModules] = useState([
    { id: 1, name: "Module 1: About Growna", videos: ["Video 1", "Video 2"] },
    { id: 2, name: "Module 2: Why Growna", videos: ["Video 1", "Video 2"] },
    { id: 3, name: "Module 3: Growna", videos: ["Video 1", "Video 2"] },
  ]);
  const [performers, setPerformers] = useState([
    {
      id: 1,
      name: "Anmol Pattnayak",
      score: 80,
    },
    {
      id: 2,
      name: "Yash Patel",
      score: 90,
    },
    {
      id: 3,
      name: "Aadarsh Kumayan",
      score: 92,
    },
    {
      id: 4,
      name: "Anuj Pattnayak",
      score: 95,
    },
    {
      id: 5,
      name: "Vithik Shah",
      score: 90,
    },
  ]);
  const [searchTermPerformers, setSearchTermPerformers] = useState("");
  const [searchTermModalUsers, setSearchTermModalUsers] = useState("");

  const [moduleState, setModuleState] = useState(() => {
    const initialState = {};
    modules.forEach((module) => {
      initialState[module.id] = false;
    });
    return initialState;
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sampleUsers, setSampleUsers] = useState([
    {
      id: 1,
      name: "Anmol Pattnayak",
      phone: "7654909876",
      enrolledCourses: 5,
      completedCourses: 3,
    },
    {
      id: 2,
      name: "Yash Patel",
      phone: "9876235543",
      enrolledCourses: 4,
      completedCourses: 2,
    },
    {
      id: 3,
      name: "Aadarsh Kumayan",
      phone: "9812876634",
      enrolledCourses: 2,
      completedCourses: 2,
    },
    {
      id: 4,
      name: "Anuj Pattnayak",
      phone: "9873476543",
      enrolledCourses: 3,
      completedCourses: 2,
    },
    {
      id: 5,
      name: "Vithik Shah",
      phone: "9872374533",
      enrolledCourses: 5,
      completedCourses: 4,
    },
  ]);
  const handleAssign = () => {
    alert(
      "Request has been submitted to adminstartor. Users will be onboarded in few hours."
    );
    setIsModalOpen(!isModalOpen);
  };

  const handleBack = () => {
    history.push("/dashboard/courses");
  };

  const toggleModule = (moduleId) => {
    setModuleState((prevState) => ({
      ...prevState,
      [moduleId]: !prevState[moduleId],
    }));
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchPerformers = (e) => {
    setSearchTermPerformers(e.target.value);
  };

  const handleSearchModalUsers = (e) => {
    setSearchTermModalUsers(e.target.value);
  };

  const filteredPerformers = performers.filter((performer) =>
    performer.name.toLowerCase().includes(searchTermPerformers.toLowerCase())
  );

  const filteredModalUsers = sampleUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTermModalUsers.toLowerCase())
  );

  const [selectedRows, setSelectedRows] = useState(
    Array(sampleUsers.length).fill(false)
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(Array(sampleUsers.length).fill(!selectAll));
  };

  const handleRowSelect = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  };

  return (
    <div className="page-container">
      <div className="back-button" onClick={handleBack}>
        <IoArrowBackCircleOutline />
      </div>
      <div className="blue-strip">
        <div className="top-video">
          <div className="video-container-mini">
            <video controls>
              <source
                src="https://storage.googleapis.com/growna-file-storage/source-video-files/course_fire_safety_98yht65fcx/mod3_vid2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <button className="assign-users" onClick={toggleModal}>
              <FaUsers />
              <p>Assign Users</p>
            </button>
          </div>
        </div>
        <p className="course-name">Get to Know Growna</p>
        <p className="provider">Growna</p>
        <div className="icon-container">
          <MdViewModule />
          <p>Number of Modules: {modules.length}</p>
        </div>
        <div className="icon-container">
          <MdOndemandVideo />
          <p>
            Number of Videos:{" "}
            {modules.reduce((total, module) => total + module.videos.length, 0)}
          </p>
        </div>
        <div className="icon-container">
          <MdPlaylistAddCheck />
          <p>Number of Tests: {modules.length}</p>
        </div>
      </div>
      <p style={{ display: "none" }}>Course ID: {id}</p> {/* Course ID */}
      <div className="course-details">
        <div className="accordion-container">
          {modules.map((module) => (
            <div
              key={module.id}
              className="accordion-item"
              onClick={() => toggleModule(module.id)}
            >
              <div className="accordion-header">
                {module.name}
                {moduleState[module.id] ? <IoChevronUp /> : <IoChevronDown />}
              </div>
              {moduleState[module.id] && (
                <div className="accordion-content">
                  <ul>
                    {module.videos.map((video, index) => (
                      <li key={index}>
                        <MdOndemandVideo />
                        <p>{video}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="side-container">
          <div className="video-container-mini">
            <video controls>
              <source
                src="https://storage.googleapis.com/growna-file-storage/source-video-files/course_fire_safety_98yht65fcx/mod3_vid2.mp"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <button className="assign-users" onClick={toggleModal}>
              <FaUsers />
              <p>Assign Users</p>
            </button>
          </div>
          <div className="performers-container">
            <div className="performers-heading">
              <p>Top Performers:</p>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermPerformers}
                  onChange={handleSearchPerformers}
                />
                <FaSearch />
              </div>
            </div>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPerformers.map((performer, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{performer.name}</td>
                      <td>{performer.score}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="heading-container">
              <h2>Users List:</h2>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermModalUsers}
                  onChange={handleSearchModalUsers}
                />
                <FaSearch />
              </div>
              <span className="close-button" onClick={toggleModal}>
                <IoClose />
              </span>
            </div>
            <div className="user-list">
              <table>
                <thead>
                  <tr>
                    <th>
                      Select All
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th>#</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Enrolled Courses</th>
                    <th>Completed Courses</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredModalUsers.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows[index]}
                          onChange={() => handleRowSelect(index)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.phone}</td>
                      <td>{user.enrolledCourses}</td>
                      <td>{user.completedCourses}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="assign-btn" onClick={handleAssign}>
              Assign
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default YourComponent;
