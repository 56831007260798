import React, { useState } from "react";
import "./style.scss";
import { IoClose } from "react-icons/io5";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

const Subcategories = ({ subcategories }) => {
  return (
    <div className="subcategories">
      {subcategories.map((subcategory) => (
        <div key={subcategory.id} className="subcategory">
          {subcategory.name}
        </div>
      ))}
    </div>
  );
};

const Category = ({
  category,
  activeTab,
  handleTabChange,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <div>
      <div
        className={`marketplace-tab ${
          activeTab === category.id ? "active" : ""
        }`}
        onClick={() => handleTabChange(category.id)}
      >
        {category.name}
        {category.subcategories && (
          <div className="accordion-icon" onClick={toggleAccordion}>
            {isOpen ? (
              <MdExpandLess style={{ fontSize: "16px" }} />
            ) : (
              <MdExpandMore style={{ fontSize: "16px" }} />
            )}
          </div>
        )}
      </div>
      {isOpen && category.subcategories && (
        <Subcategories subcategories={category.subcategories} />
      )}
    </div>
  );
};

const Categories = ({ activeTab, handleTabChange }) => {
  const [openCategory, setOpenCategory] = useState(null);

  const toggleAccordion = (categoryId) => {
    setOpenCategory(openCategory === categoryId ? null : categoryId);
  };

  const categories = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "category1",
      name: "Chemicals",
      subcategories: [
        { id: "sub1", name: "Subcategory 1" },
        { id: "sub2", name: "Subcategory 2" },
      ],
    },
    {
      id: "category2",
      name: "Manufacturing",
    },
    {
      id: "category3",
      name: "Security",
      subcategories: [
        { id: "sub3", name: "Subcategory 3" },
        { id: "sub4", name: "Subcategory 4" },
      ],
    },
    {
      id: "category4",
      name: "Technology",
      subcategories: [
        { id: "sub5", name: "Subcategory 5" },
        { id: "sub6", name: "Subcategory 6" },
      ],
    },
    {
      id: "category5",
      name: "Healthcare",
    },
  ];

  return (
    <div className="categories">
      <div className="marketplace-tabs-container">
        <p>Categories</p>
        <div className="marketplace-tabs">
          {categories.map((category) => (
            <Category
              key={category.id}
              category={category}
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              isOpen={openCategory === category.id}
              toggleAccordion={() => toggleAccordion(category.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// ... (remaining code)

const Card = ({ name, provider, status, openModal, thumbnailImg }) => {
  return (
    <div className="card-container" onClick={openModal}>
      <img src={thumbnailImg} alt="Random" className="card-image" />
      <div className="card-text">
        <p className="course-name">{name}</p>
        <p className="provider-name">{provider}</p>
        <div className="status">
          <p>{status}</p>
        </div>
      </div>
      <div className="separator"></div>
      <button className="card-button">Preview</button>
    </div>
  );
};

const SearchBar = ({ handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    handleSearch(newSearchTerm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchTerm);
  };

  return (
    <div className="search-container">
      <form className="search-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="search-input"
          placeholder="Search for something..."
          value={searchTerm}
          onChange={handleChange}
        />
        <button type="submit" className="search-button">
          <FaSearch />
        </button>
      </form>
    </div>
  );
};

const Modal = ({ closeModal }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={closeModal}>
          <IoClose />
        </span>
        <div className="video-container">
          <video controls>
            <source
              src="https://storage.googleapis.com/growna-file-storage/source-video-files/course_fire_safety_98yht65fcx/mod3_vid2.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-details">
          <div className="video-buy">
            <p className="video-name">Get To Know Growna</p>
            <button className="buy-button">Get This Course!</button>
          </div>
          <p className="video-provider">Growna</p>
        </div>
      </div>
    </div>
  );
};

function Index() {
  const [activeTab, setActiveTab] = useState("category1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (searchTerm) => {
    // Perform search logic based on the searchTerm
    // For now, let's just filter the cardData based on the name
    const filteredResults = cardData.filter((card) =>
      card.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  // Replace cardData with relevant and random data
  const cardData = [
    {
      name: "Fire Safety",
      provider: "Growna",
      status: "Enroll Now",
      thumbnailImg:
        "https://movitherm.com/wp-content/uploads/2022/12/How-does-a-fire-prevention-plan-benefit-your-workplace.png",
    },
    {
      name: "Workplace Health Safety",
      provider: "Growna",
      status: "Subscribed",
      thumbnailImg:
        "https://humanfocus.co.uk/wp-content/uploads/2020/12/why-is-health-and-safety-important-for-all-workplaces.jpg",
    },
    {
      name: "Basics of Unarmed Security",
      provider: "Growna",
      status: "Subscribed",
      thumbnailImg:
        "https://novage.ms/images/novagems-img/blogs/security-officer-and-surrounding.png",
    },
    {
      name: "Introduction to Sooryavanshi Securities",
      provider: "Sooryanashi Industries",
      status: "Enroll Now",
      thumbnailImg:
        "https://www.promansecuritech.com/wp-content/uploads/2019/05/promansecuritech-security-guard-agency-banner.jpg",
    },
  ];

  return (
    <div className="marketplace">
      <Categories activeTab={activeTab} handleTabChange={handleTabChange} />
      <div className="courses">
        <SearchBar handleSearch={handleSearch} />
        <div className="course-cards">
          {searchResults.length > 0
            ? searchResults.map((card, index) => (
                <Card
                  key={index}
                  name={card.name}
                  provider={card.provider}
                  status={card.status}
                  openModal={openModal}
                  thumbnailImg={card.thumbnailImg}
                />
              ))
            : cardData.map((card, index) => (
                <Card
                  key={index}
                  name={card.name}
                  provider={card.provider}
                  status={card.status}
                  openModal={openModal}
                  thumbnailImg={card.thumbnailImg}
                />
              ))}
        </div>
        {isModalOpen && <Modal closeModal={closeModal} />}
      </div>
    </div>
  );
}

export default Index;
