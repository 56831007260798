import { isEmpty } from 'lodash';


export function isLoggedIn() {
    const token = localStorage.getItem('token')
    if(!!token && token != '' ){
        return true;
    }
    else{
        return false;
    }
};


export function getHeaders() {
    const token = JSON.parse(localStorage.getItem('token'))
    const returnValue = {
          "X-Auth-Token": token,
    };
    return returnValue;
};
