import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { isLoggedIn } from '../Utils/index';
import Dashboard from '../../components/Dashboard/index';


function PrivateRoutes() {
    const match = useRouteMatch('/dashboard');
   if(!isLoggedIn()){
        return <Redirect to = '/' />
    }
    else{
        return(
            <Dashboard/>
        )
    }
}

export default PrivateRoutes;