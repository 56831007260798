import React from 'react';
import RoutesComp from './config/Routes/index';
import './main.scss';

class App extends React.Component {
  render(){
    return (
        <RoutesComp/>
    );
  }
}

export default App;
