// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard_wrapper .tabs_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.leaderboard_wrapper .leaderboard_header {
  color: #555b6c;
  font-weight: 600;
  padding-top: 20px;
  font-size: 18px;
}
.leaderboard_wrapper .tab_body {
  padding: 15px;
}
.leaderboard_wrapper .tab_body .leaderborad_individual {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.leaderboard_wrapper .tab_body .leaderborad_individual img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.leaderboard_wrapper .tab_body .leaderborad_individual .leaderbord_name {
  color: #000000;
  font-weight: 600;
  margin-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/Leaderboard/style.scss","webpack://./src/constant.scss"],"names":[],"mappings":"AAII;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAHR;AAKI;EACI,cCXc;EDYd,gBAAA;EACA,iBAAA;EACA,eAAA;AAHR;AAKI;EACI,aAAA;AAHR;AAIQ;EACI,aAAA;EACA,mBAAA;EACA,6BAAA;AAFZ;AAGY;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AADhB;AAGY;EACI,cC3BQ;ED4BR,gBAAA;EACA,kBAAA;AADhB","sourcesContent":["@import '../../../constant.scss';\n\n.leaderboard_wrapper{\n    // border-left: 1px solid #e6e6e6;\n    .tabs_wrapper{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 15px;\n    }\n    .leaderboard_header{\n        color: $primary-text-color;\n        font-weight: 600;\n        padding-top: 20px;\n        font-size: 18px;\n    }\n    .tab_body{\n        padding: 15px;\n        .leaderborad_individual{\n            display: flex;\n            align-items: center;\n            justify-content: space-around;\n            img{\n                width: 40px;\n                height: 40px;\n                border-radius: 50%;\n            }\n            .leaderbord_name{\n                color: $secondary-text-color;\n                font-weight: 600;\n                margin-right: 24px;\n            }\n        }\n    }\n}","$primary-text-color : #555b6c;\n$secondary-text-color : #000000;\n$primary-blue-color : #151e33;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
