import React, { useState, useEffect } from "react";
import Cards from "../../Common/cards/index";
import "./style.scss";
import Barchart from "../../Common/Barchart";
import Leaderboard from "../../Common/Leaderboard";
import axios from "axios";
import { globalConfig } from "../../../config/pathconfig/globalConfig";
import { getHeaders } from "../../../config/Utils";

const Overview = () => {
  const [cardData, setCardData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [options, setOptions] = useState({
    title: {
      text: "COURSE WATCHTIME COMPARISON",
      align: "center",
      style: {
        fontSize: "25px",
        fontWeight: "700",
        color: "#1e6cac",
      },
      margin: 20,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      backgroundColor: "#f7f8fc",
    },
    xAxis: {
      min: 0,
      crosshair: true,
      title: {
        text: "courses",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "watchtime",
      },
    },
    series: [
      {
        name: "Courses",
        color: {
          linearGradient: [0, 0, 500, 0],
          stops: [
            [0, "#2fc2cb"],
            [1, "#5363b9"],
          ],
        },
      },
    ],
  });
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${globalConfig.api_path}/overview`, {
          headers: getHeaders(),
        });
        setItems(response.data.items);
        handleData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    const fetchGraphData = async () => {
      try {
        const response = await axios.get(
          `${globalConfig.api_path}/overview/stats`,
          {
            headers: getHeaders(),
          }
        );
        setGraphData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchGraphData();
  }, []);

  useEffect(() => {
    handleGraphData();
  }, [graphData]);

  const handleGraphData = () => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      series: graphData.series,
    }));
  };

  const handleData = (data) => {
    const updatedCardData = [];

    data.items.forEach((item) => {
      let data = {};
      if (item.type === "Block") {
        data.header = item.data.display_name;
        data.headerCount = item.data.value.toString();
        updatedCardData.push(data);
      } else if (item.type === "ListGraph") {
        setTabData(item.data.items);
      }
    });

    setCardData(updatedCardData);
  };

  return (
    <div className="overview-container">
      <div className="d-flex justify-space-around cards_flex">
        {!!cardData &&
          cardData.map((item) => (
            <Cards
              key={item.header}
              header={item.header}
              headerCount={item.headerCount}
            />
          ))}
      </div>
      <div className="leaderboard_section">
        <Barchart options={options} />
        <Leaderboard
          tabs={["Monthly", "Weekly", "All Time"]}
          tabData={tabData}
        />
      </div>
    </div>
  );
};

export default Overview;
