import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaSort, FaSortUp, FaSortDown, FaArrowRight } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import "./style.scss";
import Cards from "../../Common/cards/index";

const Courses = () => {
  const history = useHistory();
  const [cardData, setCardData] = useState([
    { header: "Courses", headerCount: "4" },
    { header: "Avg Users/Course", headerCount: "20" },
    { header: "Asessments Completed", headerCount: "36" },
  ]);
  const [tableData, setTableData] = useState([
    {
      id: 1,
      thumbnailImg:
        "https://movitherm.com/wp-content/uploads/2022/12/How-does-a-fire-prevention-plan-benefit-your-workplace.png",
      name: "Fire Safety",
      completionRate: "90%",
      duration: 120,
      rating: 4.5,
      status: "Public",
    },
    {
      id: 2,
      thumbnailImg:
        "https://humanfocus.co.uk/wp-content/uploads/2020/12/why-is-health-and-safety-important-for-all-workplaces.jpg",
      name: "Workplace Health Safety",
      completionRate: "75%",
      duration: 90,
      rating: 4.0,
      status: "Public",
    },
    {
      id: 3,
      thumbnailImg:
        "https://novage.ms/images/novagems-img/blogs/security-officer-and-surrounding.png",
      name: "Basics of Unarmed Security",
      completionRate: "90%",
      duration: 120,
      rating: 4.5,
      status: "Public",
    },
    {
      id: 4,
      thumbnailImg:
        "https://www.promansecuritech.com/wp-content/uploads/2019/05/promansecuritech-security-guard-agency-banner.jpg",
      name: "Introduction to Sooryavanshi Securities",
      completionRate: "75%",
      duration: 90,
      rating: 4.2,
      status: "Private",
    },
  ]);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("id");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSort = (column) => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortColumn(column);
  };

  const displayDuration = (duration) => {
    const hours = Math.trunc(duration / 60);
    const minutes = duration % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  const filterData = (data) => {
    return data.filter((row) => {
      const searchFields = [
        "name",
        "completionRate",
        "duration",
        "rating",
        "status",
      ];
      const searchString = searchQuery.toLowerCase();
      return searchFields.some((field) =>
        String(row[field]).toLowerCase().includes(searchString)
      );
    });
  };

  const sortedData = (data) => {
    return data.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (sortOrder === "asc") {
        if (typeof aValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return aValue - bValue;
        }
      } else {
        if (typeof bValue === "string") {
          return bValue.localeCompare(aValue);
        } else {
          return bValue - aValue;
        }
      }
    });
  };

  const Row = ({ row, displayDuration, index }) => {
    return (
      <tr className="table-row-white">
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {index}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          <img src={row.thumbnailImg} alt="Random" className="thumbnail" />
        </td>
        <td
          style={{
            fontWeight: "bold",
            verticalAlign: "middle",
            textAlign: "center",
          }}
        >
          {row.name}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {row.completionRate}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {displayDuration(row.duration)}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {row.rating}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          <button
            className="status-button"
            style={{
              backgroundColor: row.status === "Private" ? "#FEF2E5" : "#EBF9F1",
              color: row.status === "Private" ? "#CD6302" : "#349D64",
            }}
          >
            {row.status}
          </button>
        </td>
        <td
          className="arrow-icon"
          style={{ verticalAlign: "middle", textAlign: "center" }}
        >
          <Link to={`/dashboard/courses/course/${row.id}`}>
            <FaArrowRight />
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <div className="courses_page">
      <div className="d-flex justify-space-around cards_flex">
        {!!cardData &&
          cardData.map((item, index) => (
            <Cards
              key={index}
              header={item.header}
              headerCount={item.headerCount}
            />
          ))}
      </div>
      <div className="course_wrapper">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="search-icon">
            <FiSearch />
          </span>
        </div>
        <div className="leaderboard_section">
          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Video</th>
                  <th onClick={() => handleSort("name")}>
                    Name{" "}
                    {sortColumn === "name" ? (
                      sortOrder === "asc" ? (
                        <FaSortUp style={{ position: "absolute" }} />
                      ) : (
                        <FaSortDown style={{ position: "absolute" }} />
                      )
                    ) : (
                      <FaSort style={{ position: "absolute" }} />
                    )}
                  </th>
                  <th onClick={() => handleSort("completionRate")}>
                    Completion Rate{" "}
                    {sortColumn === "completionRate" ? (
                      sortOrder === "asc" ? (
                        <FaSortUp style={{ position: "absolute" }} />
                      ) : (
                        <FaSortDown style={{ position: "absolute" }} />
                      )
                    ) : (
                      <FaSort style={{ position: "absolute" }} />
                    )}
                  </th>
                  <th onClick={() => handleSort("duration")}>
                    Length{" "}
                    {sortColumn === "duration" ? (
                      sortOrder === "asc" ? (
                        <FaSortUp style={{ position: "absolute" }} />
                      ) : (
                        <FaSortDown style={{ position: "absolute" }} />
                      )
                    ) : (
                      <FaSort style={{ position: "absolute" }} />
                    )}
                  </th>
                  <th onClick={() => handleSort("rating")}>
                    Rating{" "}
                    {sortColumn === "rating" ? (
                      sortOrder === "asc" ? (
                        <FaSortUp style={{ position: "absolute" }} />
                      ) : (
                        <FaSortDown style={{ position: "absolute" }} />
                      )
                    ) : (
                      <FaSort style={{ position: "absolute" }} />
                    )}
                  </th>
                  <th onClick={() => handleSort("status")}>
                    Status{" "}
                    {sortColumn === "status" ? (
                      sortOrder === "asc" ? (
                        <FaSortUp style={{ position: "absolute" }} />
                      ) : (
                        <FaSortDown style={{ position: "absolute" }} />
                      )
                    ) : (
                      <FaSort style={{ position: "absolute" }} />
                    )}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!!tableData.length &&
                  sortedData(filterData(tableData)).map((row, index) => (
                    <Row
                      key={index}
                      row={row}
                      displayDuration={displayDuration}
                      index={index + 1}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
