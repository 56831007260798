import React from 'react';
import './style.scss';

export default class Error404 extends React.Component{
    render(){
        return(
            <div className='error404_wrapper'>
                <div className='error404'>
                    <h5 className='notFound'>Page Not Found</h5>
                    <a href = '/'>Go To Homepage</a>
                </div>
            </div>
        )
    }
}