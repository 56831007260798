import React from "react";
import "./style.scss";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';


import Button from "@mui/material/Button";
import Logo from "../Assets/images/logo_cropppedd.png";
import { globalConfig } from "../../config/pathconfig/globalConfig";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@mui/material/InputLabel";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';



class Login extends React.Component {
  state = {
    email: "",
    password: "",
    phone: "",
    address: "",
    verticals: [],
    options: [
      'automotive',
      'manufacturing',
      'f&b'
    ],
    signin: true,
    value: 0,
    Text: "Login",
    name: '',
    isLogin: true,
    showPassword: false,
    showPasswordsignup: false
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  ToggleRegister = () => {
    const { isLogin, Text } = this.state;
    if (Text == "Register") {
      this.setState({
        Text: "Login",
      });
    } else {
      this.setState({
        Text: "Register",
      });
    }
    this.setState({
      isLogin: !isLogin,
    });
  };

  handleRegister = () => {
    const { email, password, phone, address, verticals, name } = this.state;
    if (
      email != "" &&
      password != "" &&
      phone != "" &&
      address != "" &&
      verticals != "" &&
      name != ""
    ) {
      axios
        .post(`https://api.growna.in/v1/company/register`, {
          email,
          password,
          phone,
          address,
          verticals,
          name
        })
        .then(function (response) {
          toast.success(
            "You are successfully registered please login to register"
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch(function (error) {
          toast.error("Please try again later!");
        });
    } else {
      toast.error("Please fill all mandatory fields!");
    }
  };

  handleLogin = () => {
    const { email, password } = this.state;
    if (email != "" && password != "") {
      axios
        .post(`https://api.growna.in/v1/company/login`, {
          email,
          password,
        })
        .then(function (response) {
          if (response.data && response.data.access_token) {
            localStorage.setItem(
              "token",
              JSON.stringify(response.data.access_token)
            );
            window.location.reload();
          }
        })
        .catch(function (error) {
          toast.error("Please try again later!");
        });
    } else {
      toast.error("Please fill all mandatory fields!");
    }
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    })
  };

  handleClickShowPasswordSignup = () => {
    const { showPasswordsignup } = this.state;
    this.setState({
      showPasswordsignup: !showPasswordsignup,
    })
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleVerticalChange = (event) => {
    const {
      target: { value },
    } = event;
    this.setState({
      verticals: value
    })
  };

  render() {
    const { Text, isLogin, verticals, showPassword, showPasswordsignup } = this.state;
    return (
      <div className="Login_dashboard_wrapper">
        <div className="login_container">
          <div className="left_container">
            {isLogin ? (
              <div className="login_form">
                <h5 className="logo_text">{Text}</h5>
                <div>
                  <TextField
                    id="standard-basic"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    name="email"
                    label="Email"
                    variant="standard"
                  />
                  <Input
                    id="standard-basic"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    placeholder='Password'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    name="password"
                    label="Password"
                    variant="standard"
                  />
                  <div className="login_btn_wrapper">
                    <p
                      onClick={() => {
                        this.ToggleRegister();
                      }}
                    >
                      Not a member ?
                    </p>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.handleLogin();
                      }}
                      className="login_btn"
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login_form">
                <h5 className="logo_text">{Text}</h5>
                <div>
                  <TextField
                    id="standard-basic"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    name="name"
                    label="Name"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    name="email"
                    label="Email"
                    variant="standard"
                  />
                <div className="row-reverse">
                  <Tooltip title={<div><h3>Password Requirement : </h3><p>Minimum 8 character in length</p>Atleast one lower case letter<p></p><p>Atleast one upper case letter</p>Atleast one number</div>}>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <Input
                    id="standard-basic"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    name="password"
                    label="Password"
                    placeholder='Password'
                    type={showPasswordsignup ? 'text' : 'password'}
                    variant="standard"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPasswordSignup}
                          onMouseDown={this.handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordsignup ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  </div>
                  <TextField
                    id="standard-basic"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    name="address"
                    label="Address"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    name="phone"
                    label="Phone"
                    type='tel'
                    variant="standard"
                  />
                  {/* <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vertical}
                    label="vertical"
                    name="vertical"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <MenuItem value={`automotive`}>Automotive</MenuItem>
                    <MenuItem value={`manufacturing`}>Manufacturing</MenuItem>
                    <MenuItem value={`f&b`}>{`f&b`}</MenuItem>
                  </Select> */}
                  <FormControl>
                  <InputLabel id="demo-multiple-checkbox-label">Vertical</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={verticals}
                    onChange={(e) => this.handleVerticalChange(e)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                  >
                    {this.state.options.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={verticals.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  {/* <TextField id="standard-basic" onChange = {(e)=>{this.handleChange(e)}} name = 'vertical' label="Vertical" variant="standard" /> */}
                  <div className="login_btn_wrapper">
                    <p
                      onClick={() => {
                        this.ToggleRegister();
                      }}
                    >
                      Already a member?
                    </p>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.handleRegister();
                      }}
                      className="login_btn"
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="right_container">
            <div className="company_detail_wrapper">
              <div className="logo_wrapper">
                <img src={Logo}></img>
              </div>
              <div className="company_detail_text">
                <h5>
                  Next Generation Upskilling Platform for Industrial Workforce!
                </h5>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Login;
