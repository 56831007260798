import React, { useState, useEffect } from "react";
import { withRouter, Switch, Route, Link } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import PieChartIcon from "@mui/icons-material/PieChart";
import ClassIcon from "@mui/icons-material/Class";
import PeopleOutlineSharpIcon from "@mui/icons-material/PeopleOutlineSharp";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Swal from "sweetalert2";
import Logo from "../Assets/images/logo.png";

import Profile from "../Dashboard/Profile/index";
import Overview from "../Dashboard/Overview/index";
import Users from "../Dashboard/Users/index";
import Courses from "../Dashboard/Courses/index";
import Course from "../Dashboard/Course/index";
import Marketplace from "../Dashboard/Marketplace/index";
import Error404 from "../Dashboard/Error404/index";

import axios from "axios";
import { globalConfig } from "../../config/pathconfig/globalConfig";
import "./style.scss";

const LogoutButton = () => {
  const handleLogout = () => {
    Swal.fire({
      title: "Do you really want to logout?",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Logout!',
      confirmButtonAriaLabel: "Thumbs up, great!",
      confirmButtonColor: "#151e33",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("LoggedOut!", "", "success");
        localStorage.clear();
        window.location.reload();
      }
    });
  };

  return (
    <div className="nav-logout" onClick={handleLogout}>
      <div>
        <PowerSettingsNewIcon />
      </div>
      <div className="logout-text">Logout</div>
    </div>
  );
};

const Dashboard = ({ history }) => {
  const [isSelected, setIsSelected] = useState("overview");
  const [formShow, setFormShow] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("selected")) {
      localStorage.setItem("selected", "overview");
    }
  }, []);

  const handleSelected = (selected) => {
    localStorage.setItem("selected", selected);
    setIsSelected(selected);
  };

  const submitQuery = () => {
    axios
      .post(`${globalConfig.api_path}/query`, { query })
      .then(toggleForm)
      .catch(toggleForm);
  };

  const toggleForm = () => {
    setFormShow(!formShow);
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const sidebarOptions = [
    { icon: <PieChartIcon />, url: "overview", text: "Overview" },
    { icon: <ClassIcon />, url: "courses", text: "Courses" },
    { icon: <StorefrontIcon />, url: "marketplace", text: "Marketplace" },
    { icon: <PeopleOutlineSharpIcon />, url: "users", text: "Users" },
    { icon: <PersonIcon />, url: "profile", text: "Profile" },
  ];

  return (
    <>
      <div className="admin-wrapper">
        <div className="sidebar_wrapper">
          <div className="logo_text">
            <Link to="/dashboard/overview">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <div className="sidebar-options">
            {sidebarOptions.map((option, index) => (
              <Link
                to={`/dashboard/${option.url}`}
                key={index}
                onClick={() => handleSelected(option.url)}
                className={isSelected === option.url ? "selected" : ""}
              >
                <div className="nav-icon">{option.icon}</div>
                <div className="nav-text">{option.text}</div>
              </Link>
            ))}
          </div>
          <LogoutButton />
        </div>
        <div className="option_List">
          <Switch>
            <Route exact path="/dashboard/profile" component={Profile} />
            <Route exact path="/dashboard/overview" component={Overview} />
            <Route
              exact
              path="/dashboard/marketplace"
              component={Marketplace}
            />
            <Route exact path="/dashboard/users" component={Users} />
            <Route path="/dashboard/courses/course/:id" component={Course} />
            <Route path="/dashboard/courses" component={Courses} />
            <Route path="*" exact={true} component={Error404} />
          </Switch>
        </div>
      </div>
      <div>
        {formShow && (
          <div className="query_form">
            <h4 className="form_heading">Post your Query here</h4>
            <TextField
              name="query"
              onChange={handleChange}
              id="standard-basic"
              label="Query"
              variant="standard"
            />
            <div className="d-flex">
              <Button onClick={submitQuery} variant="contained" color="success">
                Post Query
              </Button>
              <Button onClick={toggleForm} variant="outlined" color="error">
                Cancel
              </Button>
            </div>
          </div>
        )}
        <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
        >
          <AddIcon onClick={toggleForm} />
        </Fab>
      </div>
    </>
  );
};

export default withRouter(Dashboard);
