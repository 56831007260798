import React, { useState } from "react";
import "./style.scss";

const Leaderboard = ({ tabs }) => {
  const [granuality, setGranuality] = useState("Monthly");

  const changeGranuality = (granualitySelected) => {
    setGranuality(granualitySelected);
  };

  const tabData = [
    {
      key: "Monthly",
      items: [
        {
          name: "Pranshu Savani",
          image_url:
            "https://cdn.pixabay.com/photo/2019/10/16/16/29/human-4554846_960_720.png",
        },
        {
          name: "Saransh Saxena",
          image_url:
            "https://cdn.pixabay.com/photo/2019/10/16/16/29/human-4554846_960_720.png",
        },
        {
          name: "Mayank Bhushan",
          image_url:
            "https://cdn.pixabay.com/photo/2019/10/16/16/29/human-4554846_960_720.png",
        },
      ],
    },
  ];

  return (
    <div className="leaderboard_wrapper">
      <div className="leaderboard_header">
        <h5>Leaderboard</h5>
      </div>
      <div className="tabs_wrapper">
        {!!tabs &&
          tabs.map((item) => (
            <h4
              key={item}
              onClick={() => {
                changeGranuality(item);
              }}
              className={`granuality ${item === granuality ? "focus" : ""}`}
            >
              {item}
            </h4>
          ))}
      </div>
      <div className="tab_body">
        {tabData.map((item) => {
          if (item.key === "Monthly" && granuality === "Monthly") {
            return (
              <div key={item.key}>
                {item.items.map((singleElement, index) => (
                  <div key={index} className="leaderborad_individual">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="leaderbord_name">{index + 1}</p>
                      <img
                        src={`${singleElement.image_url}`}
                        alt={`avatar-${index}`}
                      />
                    </div>
                    <p className="leaderbord_name">{singleElement.name}</p>
                  </div>
                ))}
              </div>
            );
          } else if (item.key === "All Time" && granuality === "All Time") {
            return (
              <div key={item.key}>
                {item.items.map((singleElement, index) => (
                  <div key={index} className="leaderborad_individual">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="leaderbord_name">{index + 1}</p>
                      <img
                        src={`${singleElement.image_url}`}
                        alt={`avatar-${index}`}
                      />
                    </div>
                    <p className="leaderbord_name">{singleElement.name}</p>
                  </div>
                ))}
              </div>
            );
          } else if (item.key === "Weekly" && granuality === "Weekly") {
            return (
              <div key={item.key}>
                {item.items.map((singleElement, index) => (
                  <div key={index} className="leaderborad_individual">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="leaderbord_name">{index + 1}</p>
                      <img
                        src={`${singleElement.image_url}`}
                        alt={`avatar-${index}`}
                      />
                    </div>
                    <p className="leaderbord_name">{singleElement.name}</p>
                  </div>
                ))}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Leaderboard;
