import React from 'react';
import './style.scss';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

class Barchart extends React.Component{

    render(){
        const {  options } = this.props;
        return(
            <div className = 'barchart_wrapper'>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            </div>
        )
    }
}

export default Barchart;