// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error404_wrapper {
  width: 100%;
  height: 40vh;
  position: relative;
  padding: 20px 25px 25px 205px;
}
.error404_wrapper .error404 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.error404_wrapper img {
  max-width: 100%;
  max-height: 100vh;
}
.error404_wrapper .notFound {
  font-size: 1.8em;
  white-space: nowrap;
  margin-bottom: 15px;
}
.error404_wrapper a {
  font-size: 1.2em;
  white-space: nowrap;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Error404/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,6BAAA;AACJ;AAAI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,kBAAA;AAER;AAAI;EACI,eAAA;EACA,iBAAA;AAER;AAAI;EACI,gBAAA;EACA,mBAAA;EACA,mBAAA;AAER;AAAI;EACI,gBAAA;EACA,mBAAA;EACA,mBAAA;AAER","sourcesContent":[".error404_wrapper{\n    width: 100%;\n    height: 40vh;\n    position: relative;\n    padding: 20px 25px 25px 205px;\n    .error404{\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n        text-align: center;\n    }\n    img{\n        max-width: 100%;\n        max-height: 100vh;\n    }\n    .notFound{\n        font-size: 1.8em;\n        white-space: nowrap;\n        margin-bottom: 15px;\n    }\n    a{\n        font-size: 1.2em;\n        white-space: nowrap;\n        margin-bottom: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
