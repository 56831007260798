import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

const TableComponent = (props) => {
  const {
    tableData,
    checkBox,
    checkboxHandler,
    tableClass,
    isVisibleCheck,
    hideColumns,
    tableHeader,
  } = props;
  const iconName = tableClass ? tableClass : "";
  let columns = ["name"];
  let tableHeaders = null;
  let tableBody = null;

  const handleSort = (item) => {
    props.sortHandler(item.key);
  };

  if (tableData.length) {
    columns = Object.keys(tableData[0]);
    if (hideColumns && hideColumns.length) {
      columns = columns.filter((x) => !(hideColumns.indexOf(x) > -1));
    }
    if (checkBox) {
      columns.splice(0, 0, "");
    }

    tableHeaders = (
      <thead>
        <tr>
          {columns.map((header) => {
            return (
              <Fragment key={`header-${header}`}>
                {tableHeader.map((item) => {
                  if (header === item.key) {
                    return (
                      <th key={item.key} onClick={() => handleSort(item)}>
                        {item.value}
                        <FaSort />
                      </th>
                    );
                  }
                  return null;
                })}
              </Fragment>
            );
          })}
        </tr>
      </thead>
    );
    tableBody = (
      <tbody>
        {tableData.length ? (
          tableData.map((rowData, index) => {
            return isVisibleCheck ? (
              rowData.isVisible ? (
                <tr key={`table-body-${index}`}>
                  {columns.map((column, indx) => {
                    return (
                      <Fragment key={`column-${indx}`}>
                        {column !== "isChecked" &&
                        column !== "isEdit" &&
                        column !== "isDisabled" &&
                        column !== "isVisible" &&
                        !rowData[column] ? (
                          <td
                            key={`checkbox-${indx}`}
                            style={{ verticalAlign: "middle", textAlign: "center" }}
                          >
                            <label className="checkbox-element">
                              <input
                                type="checkbox"
                                id={rowData.subscriberId}
                                value={rowData.subscriberId || ""}
                                onChange={checkboxHandler}
                                checked={rowData.isChecked}
                                disabled={rowData.isDisabled}
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                        ) : column !== "isChecked" &&
                          column !== "isEdit" &&
                          column !== "isDisabled" &&
                          column !== "isVisible" ? (
                          <td
                            key={`data-${indx}`}
                            style={{ verticalAlign: "middle", textAlign: "center" }}
                          >
                            {rowData[column]}
                          </td>
                        ) : null}
                      </Fragment>
                    );
                  })}
                </tr>
              ) : null
            ) : (
              <tr key={`table-body-${index}`}>
                {columns.map((column, indx) => {
                  return (
                    <Fragment key={`column-${indx}`}>
                      {column !== "userData" ? (
                        <td
                          key={`data-${indx}`}
                          style={{ verticalAlign: "middle", textAlign: "center" }}
                        >
                          {rowData[column]}
                        </td>
                      ) : (
                        <td
                          key={`user-data-${indx}`}
                          style={{ verticalAlign: "middle", textAlign: "center" }}
                        >
                          <div className="table_profile">
                            <div className="align_left">
                              <h4>{rowData[column].name}</h4>
                              <h5>{rowData[column].last_updated}</h5>
                            </div>
                          </div>
                        </td>
                      )}
                    </Fragment>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr>
            <td
              key="no-data"
              align="center"
              colSpan={columns.length - 1}
              style={{ verticalAlign: "middle", textAlign: "center" }}
            >
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    );
    return (
      <div className="data_table_wrapper">
        <table className={`table-wrapper ${iconName}`}>
          {tableHeaders}
          {tableBody}
        </table>
      </div>
    );
  }
  return <div className="no-data-found">No Data Found</div>;
};

TableComponent.propTypes = {
  tableData: PropTypes.array.isRequired,
  checkBox: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  tableClass: PropTypes.string,
  isVisibleCheck: PropTypes.bool,
  sorting: PropTypes.bool,
  sortHandler: PropTypes.func,
};

TableComponent.defaultProps = {
  tableData: [],
  checkBox: false,
  checkboxHandler: () => {},
  tableClass: null,
  isVisibleCheck: false,
  sorting: false,
  sortHandler: () => {},
};

export default TableComponent;
