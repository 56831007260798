import React, { memo } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoutes from '../Routes/PrivateRoutes';
import Auth from './Auth';
import history from '../Utils/history';


function RoutesComp() {
    return(
        <Router history = { history }>
            <Switch>
                <Route path = { '/dashboard' }>
                    <PrivateRoutes/>
                </Route>
                <Route path = ''>
                    <Auth/>
                </Route>
            </Switch>
        </Router>
    )
};

export default RoutesComp;