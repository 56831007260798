import React, { useEffect, useState } from "react";
import axios from "axios";
import { globalConfig } from "../../../config/pathconfig/globalConfig";
import { getHeaders } from "../../../config/Utils";
import "./style.scss";

const ProfileField = ({ label, value }) => (
  <div className="field">
    <span className="label">{label}:</span>
    <span className="value">{value}</span>
  </div>
);

const VerticalItem = ({ item }) => (
  <span className="vertical-item" key={item}>
    {item}
  </span>
);

const Profile = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${globalConfig.api_path}/profile`, {
          headers: getHeaders(),
        });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const {
    company_data: {
      name,
      email,
      company_code,
      phone,
      employees,
      verticals,
    } = {},
  } = data;

  return (
    <div className="profile-page-wrapper">
      <div className="profile-wrapper">
        <div className="profile-inner-wrapper">
          <div className="profile-image">
            <img
              src="https://cdn.pixabay.com/photo/2019/07/19/23/16/power-plant-4349830_960_720.jpg"
              alt="profile-pic"
            />
          </div>
          <div className="divider"></div>
          <div className="profile-data">
            <div className="profile-fields-wrapper">
              <h1 className="company-name">{name}</h1>
              <ProfileField label="Email" value={email} />
              <ProfileField label="Phone" value={phone} />
              <ProfileField label="Company Code" value={company_code} />
              <ProfileField label="No. Of Employees" value={employees} />
              <ProfileField
                label="Industry Vertical"
                value={
                  verticals &&
                  verticals.map((item) => <VerticalItem item={item} />)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
