import React from 'react';
import './style.scss';

export default class Cards extends React.Component{
    render(){
        const { header , headerCount} = this.props;
        return(
            <div className = 'card_wrapper'>
                <h5 className = 'card_name'>{ header }</h5>
                <h3 className = 'card_count'>{ headerCount }</h3>
            </div>
        )
    }
}