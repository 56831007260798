// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_wrapper {
  padding: 20px 25px 25px 205px;
}
@media (max-width: 620px) {
  .user_wrapper {
    padding: 10px;
  }
}

.container-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-component-container {
  width: 100%;
  overflow: auto;
}

.user-search {
  margin: 30px 0;
  display: flex;
  align-items: center;
  align-self: flex-end;
  padding: 8px;
  border: 2px solid #cfa973;
  min-width: 250px;
  max-width: 300px;
  border-radius: 4px;
}
.user-search .search-icon {
  margin-left: 8px;
  color: #cfa973;
}
.user-search input {
  font-size: 14px;
  border: none;
  outline: none;
  flex-grow: 1;
  text-align: start;
}

@media (max-width: 1023px) {
  .table-component-container {
    max-width: 600px;
    margin: auto;
  }
  .user-search {
    align-self: center;
  }
}
@media (max-width: 840px) {
  .user_wrapper {
    padding: 40px 25px 25px 150px;
  }
  .table-component-container {
    max-width: 400px;
    margin: auto;
  }
}
@media (max-width: 620px) {
  .user_wrapper {
    padding: 20px;
  }
  .table-component-container {
    width: 280px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Users/style.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;AAAE;EAFF;IAGI,aAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AAGF;;AAAA;EACE,WAAA;EACA,cAAA;AAGF;;AAAA;EACE,cAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AAGF;AAFE;EACE,gBAAA;EACA,cAAA;AAIJ;AADE;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;AAGJ;;AACA;EACE;IACE,gBAAA;IACA,YAAA;EAEF;EAAA;IACE,kBAAA;EAEF;AACF;AACA;EACE;IACE,6BAAA;EACF;EACA;IACE,gBAAA;IACA,YAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;EAAF;EAEA;IACE,YAAA;EAAF;AACF","sourcesContent":[".user_wrapper {\n  padding: 20px 25px 25px 205px;\n  @media (max-width: 620px) {\n    padding: 10px;\n  }\n}\n\n.container-main {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.table-component-container {\n  width: 100%;\n  overflow: auto;\n}\n\n.user-search {\n  margin: 30px 0;\n  display: flex;\n  align-items: center;\n  align-self: flex-end;\n  padding: 8px;\n  border: 2px solid #cfa973;\n  min-width: 250px;\n  max-width: 300px;\n  border-radius: 4px;\n  .search-icon {\n    margin-left: 8px;\n    color: #cfa973;\n  }\n\n  input {\n    font-size: 14px;\n    border: none;\n    outline: none;\n    flex-grow: 1;\n    text-align: start;\n  }\n}\n\n@media (max-width: 1023px) {\n  .table-component-container {\n    max-width: 600px;\n    margin: auto;\n  }\n  .user-search {\n    align-self: center;\n  }\n}\n\n@media (max-width: 840px) {\n  .user_wrapper {\n    padding: 40px 25px 25px 150px;\n  }\n  .table-component-container {\n    max-width: 400px;\n    margin: auto;\n  }\n}\n\n@media (max-width: 620px) {\n  .user_wrapper {\n    padding: 20px;\n  }\n  .table-component-container {\n    width: 280px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
